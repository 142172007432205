import React from 'react';

// Components
import ProjectPageTemplate from '../../components/ProjectPageTemplate';
import DoubleImage from '../../components/DoubleImage';

// Images
import HopesFears1 from '../../assets/images/untitled-project/hopes-and-fears-01.jpg'
import HopesFears2 from '../../assets/images/untitled-project/hopes-and-fears-02.jpg'
import Crazy8 from '../../assets/images/untitled-project/crazy-8.jpg'
import FlipTheRules from '../../assets/images/untitled-project/flip-the-rules.jpg'
import IdeaCard1 from '../../assets/images/untitled-project/idea-card.jpg'
import IdeaCard2 from '../../assets/images/untitled-project/idea-card-02.jpg'
import Research1 from '../../assets/images/untitled-project/research-01.png'
import Research2 from '../../assets/images/untitled-project/research-02.png'

const TopSecretPage = () => {
  return (
    <ProjectPageTemplate>
      <div>
        <h1 className='project-page-title'>A top secrect feature for Deliveroo retaurants and customers</h1>
        <section className='project-page-section'>
          <p>Unfortunately, I can’t tell you much about this project. However, I’m pretty chuffed with the process and the outcome so I’ll do my best. There aren’t any images of UI, but there are plenty of workshop photos and output.</p>
        </section>
      </div>
      <h3 className='section-title'>Business problem</h3>
      <section className='project-page-section'>
        <p>Deliveroo was looking to improve its profits and the growth team was tasked with finding new ways to make money. Senior staff had decided to work on a form of advertising, often found in other tech products.</p>
      </section>
      <h3 className='section-title'>Opportunity</h3>
      <section className='project-page-section'>
        <p>We, a product designer, researcher and content designer, saw this as an opportunity to explore the idea, question whether it was the best way forward and encourage staff to think more broadly about solutions that could have a great benefit for restaurants and consumers.</p>
      </section>
      <h3 className='section-title'>My role</h3>
      <section className='project-page-section'>
        <ul>
          <li>Led the exploration and design, partnering closely with a researcher and content designer whilst working with senior stakeholders and a product team.</li>
          <li>Planned and facilitated workshops and ideation sessions with stakeholders and knowledge holders to help define and design the product.</li>
          <li>Engaged senior stakeholders from tech, operations and account management as well as peers in software engineering, data science and product management.</li>
          <li>Prototyped and was heavily involved in defining the formative research with restaurants, customers and Deliveroo staff.</li>
          <li>The project was put on hold after the research due to changing priorities from senior staff.</li>
        </ul>
      </section>
      <h3 className='section-title'>Case study</h3>
      <div>
        <section className='project-page-section'>
        <h3 className='section-title'>Kick off</h3>
          <p>Working closely with a researcher, we planned and facilitated a couple of workshops that involved a range of knowledge holders and stakeholders, from the head of restaurant account management to tech senior staff and data scientists. We wanted to understand the desired impact of the project across the business, highlight potential pitfalls and generate ideas.</p>
          <p><em>Exercise – Hopes and fears</em></p>
          <p>We asked everyone three questions:</p>
          <ul>
            <li>What impact will this feature have on <strong>your team's</strong> work?</li>
            <li>How will this feature affect the <strong>restaurant</strong> experience?</li>
            <li>How will this feature affect the <strong>customer</strong> experience?</li>
          </ul>
          <DoubleImage
            src1={HopesFears1}
            description1='Hopes and fears output'
            src2={HopesFears2}
            description2='Head of restaurant ops, a product manager and a data scientist'
          />
          <p><em>Exercise – Assumptions and knowledge gaps</em></p>
          <p>We asked two questions:</p>
          <ul>
            <li>What do you think you know about this feature?</li>
            <li>What would you like to know about this feature?</li>
          </ul>
          <p><em>Workshop outcome</em></p>
          <p>Operations staff and the product teams understood each others roles and worries better, we had a lot of questions and assumptions to take to research and it turned out that there wasn’t such a shared preconceived idea of what the feature was.</p>
          <p>We also learnt a few things that we weren’t expecting. For example, we came out with a long list (50+ items) of design considerations and edge cases.</p>
        </section>
        <section className='project-page-section'>
          <h5>Ideation</h5>
          <p>To generate ideas, I planned and facilitated several ideation exercises. We invited the same group of people that attended the kick off.</p>
          <ol>
            <li>Context setting with screenshots of the app and competitor screenshots</li>
            <li>Crazy 8’s</li>
            <li>Flip the rules – an exercise that forces lateral thinking and guarantees everyone come’s up with a completely new idea</li>
            <li>Idea template – A simple way to distill all of our ideas down into one actionable idea per person that’s considered the business, restaurant and customer perspective.</li>
            <li>Discussion around key themes from kick off with prompts from competitors</li>
          </ol>
          <DoubleImage
            src1={Crazy8}
            description1='Crazy 8s'
            src2={FlipTheRules}
            description2='Flip the rules'
          />
          <DoubleImage
            src1={IdeaCard1}
            description1='Idea template'
            src2={IdeaCard2}
            description2='Idea template'
          />
          <p><em>Outcome:</em></p>
          <p>We had 12 fleshed out ideas and 40+ more in the form of crazy 8’s. All ideas were created from the knowledge and expertise of a variety of stakeholders. Myself, a content designer and a researcher distilled them down into three concepts to test.</p>
        </section>
        <section className='project-page-section'>
          <h5>Prototyping</h5>
          <p>I can’t publish the prototypes online, but they ranged from a low fidelity word document in the form of an email to high fidelity click through versions of the consumer app built using Marvel.</p>
          <p>There wasn’t a need for high fidelity mockups but, with the use of our design system, it proved to be faster than building wireframes from scratch and did not distract from the discussion points in concept testing.</p>
        </section>
        <section className='project-page-section'>
          <h5>Research and analysis</h5>
          <p><em>Objective:</em> We wanted to understand the restaurant and consumer perception of the concepts. This would help us understand their feasibility and pick a direction to move forwards.</p>
          <p>We spent one week in Milan testing the concepts with restaurants and customers and finding out what the Italian restaurant operations team had learnt whilst they had been “hacking” a similar feature.</p>
          <p>The sessions were planned with a researcher, conducted by a native Italian speaker and interpreted by a translator with engineers, product managers and product marketing managers observing.</p>
          <DoubleImage
            src1={Research1}
            description1='Restaurant research'
            src2={Research2}
            description2='Customer research'
          />
        </section>
        <section className='project-page-section'>
          <h5>The project is now on hold</h5>
          <p>At this point the project was put on hold after the research due to changing priorities from senior staff. The process and deliverables were documented thoroughly and stored in the design and product folders ready for someone to pick up in the future.</p>
        </section>
      </div>
    </ProjectPageTemplate>
  )
}

export default TopSecretPage