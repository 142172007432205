import React from 'react';
import Lines from '../assets/svgs/lines.svg';

const Footer = () => {
    return (
        <footer style={{
            backgroundImage: `url(${Lines})`,
            height: '200px'
        }}>
        </footer>
    )
}

export default Footer;