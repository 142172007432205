import React from 'react';
import { Link } from 'react-router-dom';

// Styles
import './ProjectPageTemplate.css';

// Icons
import BackArrow from '../assets/icons/BackArrow';

const ProjectPageTemplate = ({ children }) => {
  return (
    <>
      <div className='project-page-wrapper'>
        <div className='content-wrapper'>
          <Link to="/" className='back-button-wrapper'>
            <div className='back-button-icon'><BackArrow /></div>
            <span className='back-button-text'>Portfolio</span>
          </Link>
          {children}
          <Link to="/" className='back-button-wrapper'>
            <div className='back-button-icon'><BackArrow /></div>
            <span className='back-button-text'>Portfolio</span>
          </Link>
        </div>
      </div>
    </>
  )
}

export default ProjectPageTemplate