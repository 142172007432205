import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

// Files
import './App.css';

// Pages
import IndexPage from './pages/IndexPage';
import NotFound from './pages/NotFound';
import RiderOnboardingPage from './pages/projects/RiderOnboardingPage';
import RiderAdminPage from './pages/projects/RiderAdminPage';
import RiderReferralsPage from './pages/projects/RiderReferralsPage';
import TopSecretPage from './pages/projects/TopSecretPage';
import WeatherAppPage from './pages/projects/WeatherAppPage';
import FlyingFishSticksPage from './pages/projects/FlyingFishSticksPage';
import TechGoosePage from './pages/projects/TechGoosePage';
import SmartPayAsYouGoPage from './pages/projects/SmartPayAsYouGoPage';
import NestInFramePage from './pages/projects/NestInFramePage';
import CV from './pages/CV';

// Components
import ScrollToTop from './components/ScrollToTop';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path='/'><IndexPage /></Route>
        <Route exact path='/rider-onboarding'><RiderOnboardingPage /></Route>
        <Route exact path='/rider-admin'><RiderAdminPage /></Route>
        <Route exact path='/rider-referrals'><RiderReferralsPage /></Route>
        <Route exact path='/top-secret'><TopSecretPage /></Route>
        <Route exact path='/whats-with-the-weather'><WeatherAppPage /></Route>
        <Route exact path='/flying-fish-sticks'><FlyingFishSticksPage /></Route>
        <Route exact path='/tech-goose'><TechGoosePage /></Route>
        <Route exact path='/nest-in-frame'><NestInFramePage /></Route>
        <Route exact path='/smart-pay-as-you-go'><SmartPayAsYouGoPage /></Route>
        <Route exact path='/cv'><CV /></Route>
        <Route><NotFound /></Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
