import React from 'react'

// Components
import ProjectPageTemplate from '../../components/ProjectPageTemplate';
import Image from '../../components/Image';
import Highlight from '../../components/Highlight';

// Images
import Header from '../../assets/images/tech-goose/header.png'
import Public from '../../assets/images/tech-goose/public.png'
import Login from '../../assets/images/tech-goose/admin-login.png'
import JobsTable from '../../assets/images/tech-goose/jobs-table.png'
import JobSearch from '../../assets/images/tech-goose/jobs-search.png'
import JobEdit from '../../assets/images/tech-goose/job-edit.png'
import JobEditGIF from '../../assets/images/tech-goose/edit-job.gif'
import CompaniesTable from '../../assets/images/tech-goose/companies-table.png'

const TechGoosePage = () => {
  return (
    <ProjectPageTemplate>
      <div>
        <h1 className='project-page-title'>Tech Goose</h1>
        <section className='project-page-section'>
          <Highlight emoji ='🐋'>
            <a href='https://www.techgoosejobs.com/ '>techgoosejobs.com</a>
            <span className='lg'> – the admin pages are private and it's run on free Heroku dynos so may take about 30 seconds to load.</span>
          </Highlight>
          <Image src={Header} />
        </section>
      </div>
      <h3 className='section-title'>Tech</h3>
      <section className='project-page-section'>
        <ul>
          <li><a className='lg' href='https://reactjs.org/' target='_blank' rel='noreferrer'>React</a></li>
          <li><a className='lg' href='https://nodejs.org/en/' target='_blank' rel='noreferrer'>Node</a> (with <a className='lg' href='https://expressjs.com/' target='_blank' rel='noreferrer'>Express</a>)</li>
          <li><a className='lg' href='https://www.mongodb.com/' target='_blank' rel='noreferrer'>MongoDB</a> (via <a className='lg' href='https://mongoosejs.com/' target='_blank' rel='noreferrer'>Mongoose</a> and <a className='lg' href='https://www.mongodb.com/cloud/atlas' target='_blank' rel='noreferrer'>Cloud Atlas</a></li>
          <li><a className='lg' href='https://clearbit.com/logo' target='_blank' rel='noreferrer'>Clearbit Logo API</a></li>
        </ul>
      </section>
      <h3 className='section-title'>Data</h3>
      <section className='project-page-section'>
        <p>The app uses data made <a href='https://www.dol.gov/agencies/eta/foreign-labor/performance' className='lg' target='_blank' rel='noreferrer'>publicly available</a> from the Department Of Labor. I used Node scripts and manual spreadsheeting, to clean the data as I needed and upload it to MongoDB.</p>
        <p>The data I ended up with told me, for a each company:</p>
        <ul>
          <li>How many H-1B applications they have sponsored in 2019 (in total and broken down by job type)</li>
          <li>The salary range for those jobs offers (in total and broken down by job type)</li>
        </ul>
        <p>I limited this to 300 companies to curate a certain type of company (startups and household names). This also made it easier to find jobs to list, which is currently done manually.</p>
      </section>
      <h3 className='section-title'>Screenshots</h3>
      <section className='project-page-section'>
        <p>Website:</p>
        <Image src={Public} />
        <p>Admin – login:</p>
        <Image src={Login} />
        <p>Admin – jobs table:</p>
        <Image src={JobsTable} />
        <p>Admin – searching for a job:</p>
        <Image src={JobSearch} />
        <p>Admin – edit a job:</p>
        <Image src={JobEdit} />
        <p>Admin - edit a job with live preview update (GIF):</p>
        <Image src={JobEditGIF} />
        <p>Admin - companies table:</p>
        <Image src={CompaniesTable} />
      </section>
    </ProjectPageTemplate>
  )
}

export default TechGoosePage