import React from 'react'

// Components
import ProjectPageTemplate from '../../components/ProjectPageTemplate';
import Image from '../../components/Image';
import Highlight from '../../components/Highlight';

// Images
import Cover from '../../assets/images/nest-in-frame/cover.png'
import Explainer from '../../assets/images/nest-in-frame/explainer.png'
import WithPlugin from '../../assets/images/nest-in-frame/with-plugin.gif'
import WithoutPlugin from '../../assets/images/nest-in-frame/without-plugin.gif'

const WeatherApp = () => {
  return (
    <ProjectPageTemplate>
      <div>
        <h1 className='project-page-title'>Nest in frame</h1>
        <p>I'm always interested in improving my workflow as a designer. Whether getting geeky over how components are built, keeping good design documentation or playing around with building Figma plugins.</p>
        <Image
            src={Cover}
          />
      </div>
      <h3 className='section-title'>Problem</h3>
      <section className='project-page-section'>
        <p>You know when an object isn't inside a frame, but you thought it was, and so you drag it out to the side then drag it back inside the frame? Nope? Just me?</p>
        <p>This is a small shortcut that will nest the selected object inside any frame that it overlaps with.</p>
        <Highlight emoji ='🖥️'><a href='https://github.com/roryhoulding/figma-nest-in-frame' target='_blank' rel='noreferrer' className='lg'>Github repository</a></Highlight>
        <Image
          src={Explainer}
          description='Using the plugin'
        />
      </section>
      <h3 className='section-title'>Demo</h3>
      <section className='project-page-section'>
        <p>Without the plugin:</p>
        <Image
          src={WithoutPlugin}
          description='Nesting an object inside a framer without the plugin'
        />
        <p>With the plugin:</p>
        <Image
          src={WithPlugin}
          description='Nesting an object inside a framer using the plugin'
        />
      </section>
      <h3 className='section-title'>Tech</h3>
      <section className='project-page-section'>
        <ul>
          <li><a className='lg' href='https://www.figma.com/plugin-docs/intro/' target='_blank' rel='noreferrer'>Figma plugins API</a></li>
          <li>Javascript with Typescript (I'm very much still learning)</li>
        </ul>
      </section>
    </ProjectPageTemplate>
  )
}

export default WeatherApp