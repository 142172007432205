import React from 'react';

// Components
import ProjectPageTemplate from '../../components/ProjectPageTemplate';
import DoubleImage from '../../components/DoubleImage';
import Image from '../../components/Image';
import Highlight from '../../components/Highlight';

// Images
import SingaporeCentre from '../../assets/images/rider-onboarding/singapore-onboarding-centre.jpg';
import CardSort from '../../assets/images/rider-onboarding/card-sort-exercise.jpg';
import MarketAgnostic from '../../assets/images/rider-onboarding/market-agnostic.jpg';
import SingaporeFlow from '../../assets/images/rider-onboarding/singapore-flow.jpg';
import Whiteboard from '../../assets/images/rider-onboarding/whiteboard.jpg';
import Blueprint from '../../assets/images/rider-onboarding/blueprint.jpg';
import Overview from '../../assets/images/rider-onboarding/Overview.jpg';
import RTWWireframes from '../../assets/images/rider-onboarding/rtw-wireframes.jpg';
import VehicleCheckWireframes from '../../assets/images/rider-onboarding/vehicle-check-wireframes.jpg';
import Feedback1 from '../../assets/images/rider-onboarding/feedback-1.jpg';
import Feedback2 from '../../assets/images/rider-onboarding/feedback-2.jpg';
import MVPLayouts from '../../assets/images/rider-onboarding/mvp-layouts.jpg';
import VisualExplorations from '../../assets/images/rider-onboarding/visual-explorations.jpg';
import RTWHifi from '../../assets/images/rider-onboarding/rtw-hifi.jpg';
import HubMessaging from '../../assets/images/rider-onboarding/hub-messaging.jpg';
import DetailedFlow from '../../assets/images/rider-onboarding/detailed-flow.jpg';
import System from '../../assets/images/rider-onboarding/system.jpg';
import DocCheckTool from '../../assets/images/rider-onboarding/doc-check-tool.jpg';
import DocCheckData from '../../assets/images/rider-onboarding/doc-check-data.jpg';
import Cover from '../../assets/images/rider-onboarding/rider-onboarding-cover.jpg';

const RiderOnboardingPage = () => {
  return (
    <ProjectPageTemplate>
      <div>
        <h1 className='project-page-title'>Redesigning the rider onboarding experience at Deliveroo</h1>
        <p>Deliveroo was <a href='https://www.ft.com/content/cf0c5fce-3112-11e8-b5bf-23cb17fd1498' target='_blank' rel='noreferrer'>Europe’s fastest growing company ↗</a> and needed to scale quickly. I led the design of the new rider onboarding experience and internal tools – helping to grow our rider fleet by 20% and reduce costs by 7%.</p>
        <Image src={Cover} />
      </div>
      <h3 className='section-title'>The problem</h3>
      <section className='project-page-section'>
        <p>Deliveroo was struggling to meet customer demand because we didn’t have enough riders to deliver the food. On top of this, onboarding new riders was expensive, about 5% of our non-fixed costs. Conversion was low, marketing costs were high and the tools used to onboard riders were inefficient. The existing experience was pieced together using 3rd party tools by operations teams, so we decided to build our own in-house product.</p>
      </section>
      <h3 className='section-title'>My role</h3>
      <section className='project-page-section'>
        <ul className='divider-list'>
          <li>Led design of the applicant experience and internal tools</li>
          <li>Was hands on with research, planning and facilitating research and co-creation workshops and carried out a lot of interviews. </li>
          <li>I took on more than a typical designers responsibilities – ran retros, engaged stakeholders and ops teams, helped plan the launch and get buy in from around the company, presenting the vision to 100+ people.</li>
        </ul>
      </section>
      <h3 className='section-title'>Impact</h3>
      <section className='project-page-section'>
        <ul className='divider-list'>
          <li>Grew the rider fleet by 20%, enabling Deliveroo to grow to 60,000 riders worldwide</li>
          <li>Fastest growing European company in 2018 (#2 in 2019)</li>
          <li>Internal tools were 3x quicker, saving 7% of costs per year</li>
        </ul>
      </section>
      <h3 className='section-title'>Case study</h3>
      <div>
        <section className='project-page-section'>
          <h3 className='section-title'>Understanding the existing experience</h3>
          <ul>
            <li>I onboarded as a rider</li>
            <li>I interviewed the local operations team in all 12 countries</li>
            <li>With a researcher, we went to onboarding centres to observe and interview to applicants and staff in the UK, Singapore, France and Belgium</li>
            <li>Ran co-creation sessions with operations managers and onboarding centre staff to define their ideal process</li>
          </ul>
          <DoubleImage
            src1={SingaporeCentre}
            description1='Deliveroo staff and applicants in the Singapore onboarding centre'
            src2={CardSort}
            description2='Co-creation card sorting exercise with onboarding staff in Edinburgh'
          />
          <p>As an example, Singapore, the simplest applicant experience, looked like this:</p>
          <Image
            src={SingaporeFlow}
            description='The existing Singapore flow'
          />
          <p>There were some common operational tasks too:</p>
          <ul>
            <li>Check that the documents uploaded by an applicant are valid</li>
            <li>Create and manage time slots for applicants to visit onboarding centres</li>
            <li>Call applicants to encourage them to continue</li>
          </ul>
        </section>
        {/* <section className='project-page-section'>
          <h5>Defining the problems</h5>
          <p>Based on the research, we realised that:</p>
          <ul>
            <li>We weren’t preparing people for onboarding by letting them know what they will need or how long it will take. For example, applicants need documents such as their proof of right to work, and information such as their five year address history. These were not things that people usually have on them, so we saw a lot of drop off at these stages.</li>
            <li>A lot of applicants and staff time was wasted in onboarding centres because applicants didn’t know what to bring and the lack of information led to confusion.</li>
            <li>People weren’t starting to make deliveries because they were nervous or didn’t know what to do.</li>
            <li>The onboarding experience was built on a combination of 8+ different 3rd party platforms, meaning inconsistent experiences and usability throughout for applicants.</li>
            <li>The tools that onboarding staff use could not handle onboarding riders at the scale we needed to. In one case it took two days of manual work to update eight emails.</li>
            <li>Nothing could be automated because the different software we were using couldn’t talk to each other.</li>
          </ul>
          <p>We also had a long list of usability problems that would be useful further into the project.</p>
        </section> */}
        <section className='project-page-section'>
          <h5>Designing the system</h5>
          <p>We knew what data we needed to collect from applicants and the problems they were having. We had lots of ideas from co-creation sessions, it was time to piece them together.</p>
          <p>Pairing with the product manager, we defined the new system for applicants and Deliveroo staff.</p>
          <DoubleImage
            src1={Whiteboard}
            description1='Designing the new process'
            src2={Blueprint}
            description2='Blueprint output'
          />
        </section>
        <section className='project-page-section'>
          <h5>Example problems and system level solutions</h5>
          <p>Applicants didn't know what they needed, and would drop off when they didn't have the correct documents or information. For example – if you want to make deliveries on a scooter, you will need a driving licence.</p>
          <ul>
            <li>We decided to start by asking a few basic questions that determine everything you need and then show a list.</li>
            <li>We made the process non-linear. If an applicant didn't have certain documents or information on them, they could return to the 'hub' and complete a different section.</li>
          </ul>
          <Image
            src={Overview}
            description='Basic information capture at the beginning of the experience, followed by the "hub"'
          />
          <p>Staff and applicant time was wasted at onboarding sessions filling completing applicant profiles and applicants would turn up with the wrong documents.</p>
          <ul>
            <li>We decided to make most of the process remote, including document upload. This way applicants had been pre-approved and knew what documents to bring.</li>
          </ul>
          <p>The forms and questions were confusing applicants, because everyone saw the same questions and information, regardless of what they needed to see</p>
          <ul>
            <li>Show one question at a time to make it easier to parse, especially for the applicants who didn’t have English as a first language.</li>
            <li>This allowed us to build variable forms that would show different info and ask different questions depending on previous answers.</li>
          </ul>
          <Image
            src={RTWWireframes}
            description='Variable forms and one page questions during the right to work check flow. Work in progress wireframes, not representative of final experience'
          />
          <Image
            src={VehicleCheckWireframes}
            description='Variable forms and one page questions during the vehicle check flow. Work in progress wireframes, not representative of final experience'
          />
        </section>
        <section className='project-page-section'>
          <h5>Design feedback</h5>
          <p>I invited operations teams in each country, the onboarding product team and legal teams to silent crits. This highlighted information we had missed, sections we could remove, experience improvements and engineering difficulties early on.</p>
          <DoubleImage
            src1={Feedback1}
            description1='Feedback session with product team'
            src2={Feedback2}
            description2='Feedback session with product team'
          />
        </section>
        <section className='project-page-section'>
          <h5>High fidelity design explorations</h5>
          <p>I partnered closely with a content designer and briefed an illustrator.</p>
          <p>The most important consideration – content structure and visual hierarchy, was made easy by the decision to only show one question per page.</p>
          <Image
            src={MVPLayouts}
            description='Final page structures for first release'
          />
          <Image
            src={RTWHifi}
            description='Visual design and content for the right to work check section'
          />
        </section>
        <section className='project-page-section'>
          <h5>Testing and iteration</h5>
          <p>We tested in onboarding centres, with a focus on how well people understood the process and content.</p>
          <p><em>Example finding and solution:</em></p>
          <p>There is a point at which applicants need their documents to be checked and can’t progress until they are approved. This wasn't clear to applicants, many of whom thought they were blocked.</p>
          <p>We introduced a new pattern to signal important information to applicants.</p>
          <Image
            src={HubMessaging}
            description='Before testing on the left. After testing on the right'
          />
        </section>
        <section className='project-page-section'>
          <h5>Detailed spec</h5>
          <p>There were about 5-10 complex sections (series of data collection questions) to be created for each country. For such a large project, detailed specification and good communication with engineers was crucial. The annotated detailed flows showed data to be collected and sent via API's to other products.</p>
          <Image
            src={DetailedFlow}
            description='Example detailed flow – uploading proof of right to work'
          />
          <Image
            src={System}
            description='System diagram'
          />
        </section>
        <section className='project-page-section'>
          <h5>Internal tools</h5>
          <Highlight emoji='👀'>This is not a full case study</Highlight>
          <p>The most common and time consuming tasks were:</p>
          <ul>
            <li>Check that the documents uploaded by an applicant are valid</li>
            <li>Create and manage time slots for applicants to visit onboarding centres</li>
            <li>Call applicants to encourage them to continue their application</li>
          </ul>
          <p>Research:</p>
          <ul>
            <li>I was responsible for most of the research for internal tools in UK, Singapore, France and Belgium</li>
            <li>Interviewed and observed Deliveroo staff and ran card sorting exercises with them to determine the main problems and pain points</li>
            <li>The main problem was that staff had to use multiple tools to and were looking for lots of data points across several pages to approve each check each applicant documents</li>
          </ul>
          <p>Document checking solution: one tool, with only the needed information and actions on one page.</p>
          <Image
            src={DocCheckTool}
            description='Rider document check tool'
          />
          <Image
            src={DocCheckData}
            description='The document check tool had to handle a complex array of data for different document types. This is the UK data'
          />
        </section>
      </div>
      <h3 className='section-title'>Launch</h3>
      <section className='project-page-section'>
        <p>As a team, we decided to build for the simplest market first so we could launch and test sooner. This was Singapore, our 3rd most important market at the time.</p>
        <p>My role during launch in Singapore:</p>
        <ul>
          <li>Worked with engineers to fix bugs and ran prioritisation sessions</li>
          <li>Worked with a researcher to interview applicants as they onboarded</li>
          <li>Observed staff using the new tools and did time on task testing to measure success</li>
          <li>Planned and facilitated retros with the Singapore operations team to see how we could improve our launch process for the next market</li>
        </ul>
      </section>
      <h3 className='section-title'>Impact</h3>
      <section className='project-page-section'>
        <ul>
          <li>Increased the number of applicants who made at least 1 delivery by 20% in the first two launch countries, Singapore and the UK. Enabling Deliveroo to grow to 60,000 riders worldwide.</li>
          <li>Internal tools were 3x quicker, saving 7% of costs per year</li>
        </ul>
      </section>
    </ProjectPageTemplate>
  )
}

export default RiderOnboardingPage