import React from 'react';

// Styles
import './Image.css'

const Image = ({ src, description }) => {
  return (
    <div className='single-image-wrapper'>
      <figure>
        <a href={src} target='_blank' rel='noreferrer'>
          <img src={src} alt={description} /> 
        </a>
        <figcaption>
          <span className='small-text lg'>
            {description ? `${description}. ` : null}<a href={src}><span className='lg'>View high res ↗</span></a>
          </span>
        </figcaption>
      </figure>
    </div>
  )
}

export default Image;