import React from 'react'

// Components
import ProjectPageTemplate from '../../components/ProjectPageTemplate';
import Image from '../../components/Image';
import Highlight from '../../components/Highlight';

// Images
import BetaTopUp from '../../assets/images/bulb-smart-pay-as-you-go/beta-top-up.png'
import TopUpHistoryWeb from '../../assets/images/bulb-smart-pay-as-you-go/top-up-history-web.png'
import Sketches1 from '../../assets/images/bulb-smart-pay-as-you-go/early-sketches-1.png'
import Sketches2 from '../../assets/images/bulb-smart-pay-as-you-go/early-sketches-2.png'
import NorthStarHome from '../../assets/images/bulb-smart-pay-as-you-go/north-star-home.png'
import NorthStarAccount from '../../assets/images/bulb-smart-pay-as-you-go/north-star-account.png'
import NorthStarTopUp from '../../assets/images/bulb-smart-pay-as-you-go/north-star-top-up.png'
import KillerFeatureOutput from '../../assets/images/bulb-smart-pay-as-you-go/killer-feature-output.png'
import MVPPrioritisation1 from '../../assets/images/bulb-smart-pay-as-you-go/mvp-prioritisation-1.jpg'
import MVPPrioritisation2 from '../../assets/images/bulb-smart-pay-as-you-go/mvp-prioritisation-2.jpg'
import MVPPrioritisation3 from '../../assets/images/bulb-smart-pay-as-you-go/mvp-prioritisation-3.jpg'
import MVPPrioritisation4 from '../../assets/images/bulb-smart-pay-as-you-go/mvp-prioritisation-4.jpg'
import Cover from '../../assets/images/bulb-smart-pay-as-you-go/cover.jpg'

const SmartPayAsYouGoPage = () => {
  return (
    <ProjectPageTemplate>
      <div>
        <h1 className='project-page-title'>Smart Pay As You Go for Bulb customers</h1>
        <p>There are 4.3 million households with prepay energy meters in the UK. Bulb didn’t offer a smart meter prepay product and saw it as a major opportunity for growth. I designed and helped launch a beta in only a few months as they became the fastest growing European company in 2021.</p>
        <Image src={Cover} />
      </div>
      <h3 className='section-title'>My role</h3>
      <section className='project-page-section'>
          <ul className='divider-list'>
            <li>Only designer in a product team of full stack engineers, a PM, an operations manager and customer support</li>
            <li>Collaborated with a content designer and researcher</li>
            <li>Designed the beta smart pay as you go experience and developed the future vision too</li>
            <li>I took an active role supporting and enabling decision making, for example, through planning and running prioritisation workshops</li>
          </ul>
        </section>
      <h3 className='section-title'>Work</h3>
      <div>
        <section className='project-page-section'>
          <Highlight emoji ='👀'>This is not a full case study. If you are interested in seeing how I work in more detail, I suggest taking a look at some of my <a href='/'>Deliveroo case studies</a>.</Highlight>
          <h5>Context</h5>
          <p>There are two ways to pay for your energy in the UK:</p>
          <ul>
            <li><em>Direct debit</em> - Paid automatically from your bank account. You pay a set amount once per month, like a monthly phone contract. This is how most people pay.</li>
            <li><em>Pay as you go (what i worked on)</em> - You top up your energy meter when it runs out of money. You can top up as much as you want, like a pay as you go sim card.</li>
          </ul>
        </section>
        <section className='project-page-section'>
          <h5>Opportunity</h5>
          <p>Bulb provided a ‘legacy’ pay as you go product, where people had to go to the shop to top up their meter. They didn’t have a ‘smart’ pay as you go product that allowed customers to top up online at any time.</p>
          <p>Based on research and existing competitor offerings, the user needs and business opportunity were clear:</p>
          <ul>
            <li>Bulb estimated it could grow its customer base by 300,000+ and already had people signed up on the waitlist.</li>
            <li>For customers, it was time consuming and choresome to go to the shops to top up, being able to top up on the website or in the app would make it easier and more convenient.</li>
            <li>Customers on legacy pay as you go have to keep an eye on their physical meter (often outside) to make sure they didn't run out of credit. Being able to check online from anywhere would make it easier to keep track of balance and ensure their energy didn't get turned off.</li>
          </ul>
        </section>
        <section className='project-page-section'>
          <h5>Impact</h5>
          <ul>
            <li>We created an MVP we were proud of, one that delivered a few features well rather than lots of features half baked.</li>
            <li>With focused priorities and strong designer, developer and researcher collaboration, we designed and built the MVP very quickly.</li>
            <li>The beta app launched shortly after I left Bulb.</li>
          </ul>
        </section>
        <section className='project-page-section'>
          <h5>Examples of my work</h5>
          <p><strong>Top up experience - beta (app version)</strong>. This is the happy path for someone topping up their energy meter in the app.</p>
          <Image src={BetaTopUp} />
          <p><strong>Top up history - beta (web version)</strong></p>
          <Image src={TopUpHistoryWeb} />
          <p><strong>Early sketches for the app</strong>. I always start with pen and paper.</p>
          <Image src={Sketches1} />
          <Image src={Sketches2} />
          <p><strong>North star for the home screen</strong>. I explored utilising the wasted space on the home screen and simplifying the apps information architecture.</p>
          <Image src={NorthStarHome} />
          <p><strong>North star for the top up experience</strong>. The flow remains the same with updated UI components.</p>
          <Image src={NorthStarTopUp} />
          <p><strong>North star for the account section</strong>.</p>
          <Image src={NorthStarAccount} />
          <p><strong>Examples of the output from a 'killer feature' workshop</strong>. A cross discipline ideation workshop, involving customer service, data science, design, product and engineers.</p>
          <Image src={KillerFeatureOutput} />
          <p><strong>MVP prioritisation workshop</strong>. A workshop I ran towards the beginning of my time at Bulb to help the team work out our priorities for the MVP.</p>
          <Image src={MVPPrioritisation2} />
          <Image src={MVPPrioritisation1} />
          <Image src={MVPPrioritisation3} />
          <Image src={MVPPrioritisation4} />
        </section>
      </div>
    </ProjectPageTemplate>
  )
}

export default SmartPayAsYouGoPage