import React from 'react';
import { Link } from 'react-router-dom';

// Components
import Pill from './Pill';

// Styles
import './ProjectCardCompact.css'

// Icons
import ForwardArrow from '../assets/icons/ForwardArrow.js'

const ProjectCard = ({path, title, subtitle, pill}) => {
  let pillContent = (() => {
    switch (pill) {
      case 'deliveroo':
        return 'Deliveroo';
      default:
        return 'Personal project';
    }
  })();

  return (
    <Link to={path} className='project-card-compact'>
      <div className='details'>
          <span className='title'>{title}</span>
          <span className='subtitle'>{subtitle}</span>
          <Pill color={pill} text={pillContent}></Pill>
      </div>
      <span className='arrow'><ForwardArrow /></span>
    </Link>
  )
}

export default ProjectCard