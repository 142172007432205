import React from 'react';

// Components
import ProjectPageTemplate from '../../components/ProjectPageTemplate';
import Image from '../../components/Image';

// Images
import ABTest from '../../assets/images/rider-referrals/ab-test.jpg'
import Existing from '../../assets/images/rider-referrals/existing-experience.jpg'
import Wireframes from '../../assets/images/rider-referrals/wireframes.jpg'
import Prototypes from '../../assets/images/rider-referrals/prototypes.jpg'
import HiFi from '../../assets/images/rider-referrals/high-fidelity.jpg'

const RiderReferralsPage = () => {
  return (
    <ProjectPageTemplate>
      <div>
        <h1 className='project-page-title'>Referrals in the Deliveroo rider app</h1>
        <p>During high demand, Deliveroo often struggled to have enough riders to fulfil customer orders. This leads to late deliveries, cancelled orders and sometimes people can't order at all.</p>
        <Image
            src={ABTest}
            description='A few screens from the A/B test'
          />
      </div>
      <h3 className='section-title'>Opportunity</h3>
      <section className='project-page-section'>
        <p>Rider referrals are the most cost effective way of getting new riders and the people that were referred by another rider make more deliveries and stay with Deliveroo longer.</p>
        <p>The referral experience was lackluster and uninformative so we decided to experiment to see how we could increase the number of successful referrals.</p>
      </section>
      <h3 className='section-title'>My role</h3>
      <section className='project-page-section'>
        <ul>
          <li>I was the only designer, working closely with the product and engineering team with. I had a small amount of support from a researcher and content designer.</li>
          <li>Planned research objectives, wrote the discussion guide and built prototypes.</li>
          <li>Based on the findings I was responsible for evolving the existing experience, working closely with the new design systems team and the branding team for illustrations.</li>
          <li>Contributed to product strategy, working with the product manager and data scientist and helped define success metrics and A/B tests.</li>
        </ul>
      </section>
      <h3 className='section-title'>Impact</h3>
      <section className='project-page-section'>
        <p>We saw a big increase in the number of successful referrals, estimated to save Deliveroo £1.4m per year.</p>
      </section>
      <h3 className='section-title'>Case study</h3>
      <div>
        <section className='project-page-section'>
        <h3 className='section-title'>Existing experience</h3>
          <p>It was clear that the current experience provided the bare minimum and lacked a lot of information or control. We didn't do formative research upfront. Instead we decided to make a few assumptions and test concepts later.</p>
          <Image
            src={Existing}
            description='Existing rider referral flow'
          />
        </section>
        <section className='project-page-section'>
          <h5>Exploration and making assumptions</h5>
          <p>These ideas came about from casual conversations with the product manager of the team and people from the rider operations team. It was a quick project so I began to explore a few of them.</p>
          <p><em>How can we encourage riders to make more referrals?</em></p>
          <ul>
            <li>Celebrate when riders make a successful referral and encourage them to make another</li>
            <li>Gamify referrals and offer bonuses for frequency referrals or offer a reward to referral streaks</li>
          </ul>
          <p><em>How can we encourage riders to nudge or help people they have referred?</em></p>
          <ul>
            <li>Message riders to let them know when someone has used their code</li>
            <li>Allow riders to track the progress of people who have used their referral code</li>
            <li>Offer extra monetary incentives</li>
          </ul>
          <Image
            src={Wireframes}
            description='Conceptual wireframes'
          />
        </section>
        <section className='project-page-section'>
          <h5>Research and testing</h5>
          <p>We had three main objectives for the research:</p>
          <ul>
            <li>Understand rider’s motivations and behaviours when referring someone. For example, who do they refer and when do they give out their referral code?</li>
            <li>Understand how the current referral experience meets their needs and where it lets them down</li>
            <li>Test the new concepts</li>
          </ul>
          <p>I planned the research objectives and discussion guide, with feedback from a researcher, and paired up with another designer, who also wanted to do research with riders, to organise the sessions. We met up with riders in a cafe near where they worked and were supported by a researcher when conducting the interviews.</p>
          <p>We tested four concepts, all with static image prototypes. The referral tracking page was populated with riders real data which meant we got real reactions from riders, some surprised to see who had used their code.</p>
          <Image
            src={Prototypes}
            description=' Static image prototypes for concept testing, displayed on an iPhone'
          />
        </section>
        <section className='project-page-section'>
          <h5>Findings and iteration</h5>
          <p>The findings from the research generally backed up our assumptions and provided a few insights we hadn’t considered.</p>
          <p>Key findings from the research:</p>
          <ul>
            <li>Riders generally refer a mix of friends, riders they know from other companies and strangers who asked them about making deliveries on the street.</li>
            <li>Riders are more likely to stay in touch with and encourage people that they know.</li>
            <li>Most people are giving their referral code out but don’t know whether people have completed the onboarding.</li>
            <li>Referring people is reactive – they give their code out when they are asked about it.</li>
          </ul>
          <p>Concept testing:</p>
          <ul>
            <li>The tracking screen was well received by riders. There was a real desire to know who had used their code and several complained about not having it already. Several mentioned that they would contact their friends who were applying.</li>
            <li>Additional monetary incentives and giving riders control proved less valuable.</li>
          </ul>
        </section>
        <section className='project-page-section'>
          <h5>Refining the designs and constraints</h5>
          <p>Based on the research, we decided to move forward with tracking page and notifications as it seemed they would have the biggest impact. We had a good idea of the information that riders would be interested in but, due to time constraints we couldn’t be as specific or clever as we wanted.</p>
          <p>I worked closely with the design systems team to help define the new progress row component and briefed the Deliveroo illustrators, giving feedback along the way. Other than that, the execution was simple, mainly using rows and card components. This allowed for a quick turnaround with little feedback needed for visual design.</p>
          <Image
            src={HiFi}
            description='Tracking screen – first release and improvements'
          />
        </section>
        <section className='project-page-section'>
          <h5>A/B test</h5>
          <p><em>Hypothesis:</em> Giving riders visibility and positive feedback about the progress of their referrals will mean they encourage referees through the onboarding process and will encourage riders to make more referrals.</p>
          <p><em>Success metric:</em> Riders will make more completed referrals.</p>
          <p>The test went live for 3 weeks in the UK and Ireland. During the experiment, 50% of riders saw the old referral page (the control) and 50% saw the new experience and received notifications.</p>
        </section>
        <section className='project-page-section'>
          <h5>Experiment results</h5>
          <p>The new designs improved the referral rate by 22%. This was considered significant and will save Deliveroo £1.4m a year and so will be rolled out globally.</p>
        </section>
      </div>
    </ProjectPageTemplate>
  )
}

export default RiderReferralsPage