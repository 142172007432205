import React from 'react';

// Components
import ProjectPageTemplate from '../../components/ProjectPageTemplate';
import DoubleImage from '../../components/DoubleImage';
import Image from '../../components/Image';

// Images
import Cover from '../../assets/images/internal-tools/cover.jpg';
import Existing from '../../assets/images/internal-tools/existing.jpg';
import Audit1 from '../../assets/images/internal-tools/audit.jpg';
import Audit2 from '../../assets/images/internal-tools/audit-02.jpg';
import Banner from '../../assets/images/internal-tools/banner.jpg';
import DataAnalysis from '../../assets/images/internal-tools/data-analysis.jpg';
import CardSort1 from '../../assets/images/internal-tools/card-sort-01.jpg';
import CardSort2 from '../../assets/images/internal-tools/card-sort-02.JPG';
import ExistingAdmin from '../../assets/images/internal-tools/existing-admin.jpg';
import Hierarchy from '../../assets/images/internal-tools/hierarchy.jpg';
import InfoPageExplorations from '../../assets/images/internal-tools/Info-page-explorations.jpg';
import EngFeedback from '../../assets/images/internal-tools/eng-feedback.jpg';
import IndexPage from '../../assets/images/internal-tools/index-page.jpg';
import InfoPageTesting from '../../assets/images/internal-tools/information-page-testing.jpg';
import InfoPageFinal from '../../assets/images/internal-tools/information-page-final.jpg';
import BadStatusBanner from '../../assets/images/internal-tools/bad-status-banner.jpg';
import Details2 from '../../assets/images/internal-tools/Details-02.jpg';
import Details3 from '../../assets/images/internal-tools/Details-03.jpg';
import Welcome from '../../assets/images/internal-tools/welcome.jpg';

const RiderAdminPage = () => {
  return (
    <ProjectPageTemplate>
      <div>
        <h1 className='project-page-title'>Internal tools for rider admin teams at Deliveroo</h1>
        <p>Deliveroo’s rider tools are used by operations teams in 13 countries to manage 60,000 delivery riders. They had grown in a patch-work manner as Deliveroo scaled quickly, becoming inefficient and hard to use. With limited time and scope, I ran a data led (qual and quant) redesign.</p>
        <Image
            src={Cover}
            description='First release for the rider information page'
          />
      </div>
      <h3 className='section-title'>Business problem</h3>
      <section className='project-page-section'>
        <p>Rider Admin is Deliveroo’s set of internal tools for accessing data about riders, making changes to their accounts and managing the fleet. As part of a larger engineering infrastructure project to ensure Deliveroo’s products were built in a scalable way, the Rider Lifecycle engineering team were rebuilding Rider Admin from scratch.</p>
      </section>
      <h3 className='section-title'>Opportunity</h3>
      <section className='project-page-section'>
        <p>Rider Admin had become messy and hard to use. There wasn't a dedicated team and nobody knew why it was designed the way it was. Every time a feature was requested by the operations or legal teams, it was added with little regard for the rest of the product.</p>
        <p>The rebuild was an opportunity to redesign Rider Admin to bring it inline with the new design system and make it more efficient to use. This would also set us up for other projects we had planned.</p>
      </section>
      <h3 className='section-title'>My role</h3>
      <section className='project-page-section'>
        <ul>
          <li>Led the redesign with limited time and resources</li>
          <li>Analysed usage data with Google analytics</li>
          <li>Facilitated IA exercises with product designers and a researcher</li>
          <li>Collaborated closely with the engineering team and a researcher</li>
        </ul>
      </section>
      <h3 className='section-title'>Case study</h3>
      <div>
        <section className='project-page-section'>
          <h3 className='section-title'>Product Audit – understanding the existing product</h3>
          <p>We needed to understand what we had to rebuild, so I did an audit. It seemed simple, if a little unorganised and hard to parse. There were two pages - a rider search page and a rider information page. However, it was riddled with pop ups, hidden information and complex data relationships.</p>
          <Image
            src={Existing}
            description='The search and index page above, the rider information page below'
          />
          <Image
            src={Audit1}
            description='Pop ups, hidden pages and complex data relationships'
          />
          <p>The audit formed a 15 page document of all the data and actions you could take. It became an important reference to make sure we didn’t miss any functionality.</p>
          <Image
            src={Audit2}
            description='A few pages of the audit document'
          />
        </section>
        <section className='project-page-section'>
          <h5>Research</h5>
          <p>We needed to find out who was using the product, what they were trying to achieve and how they were doing so.</p>
          <p>Deliveroo had offices in 12 countries and plenty more cities, so we started with a survey. To reach as many people as possible we built a link to the survey on the search page and information pages.</p>
          <Image
            src={Banner}
            description='The banner with a link to the survey. Crude but quick'
          />
        </section>
        <section className='project-page-section'>
          <h5>Survey results</h5>
          <p>The survey had over 200 responses. I went through the data, analysed it, and found that:</p>
          <ul>
            <li>40% of people were using it to solve ‘operational critical’ problems to do with live orders. For example – helping a rider with their order or telling a customer how far away their rider was.</li>
            <li>This group made up 70% of people using the product all day every day, and therefore most of the usage.</li>
            <li>The remaining people were mostly using it to manage and set up rider accounts or find out other information about a rider.</li>
            <li>We couldn't remove any actions as all of them were being used substantially. For example – taking a rider off an order because they have broken down.</li>
            <li>We understood which teams were using it the most and used this to choose who to do task based testing with later on.</li>
          </ul>
        </section>
        <section className='project-page-section'>
          <h5>Data analysis</h5>
          <p>Alongside the survey, we could learn a lot from Google analytics. With data science stretched, I was in charge. Events weren’t tracked, however I realised that each filter on the rider search was executed through a URL parameter. This meant that usage of filters and search could be tracked through pageviews.</p>
          <Image
            src={DataAnalysis}
            description='Key findings from the data analysis'
          />
          <p>Based on the insights we could make a few changes:</p>
          <ul>
            <li>remove zone move status filter</li>
            <li>remove pending riders filter</li>
            <li>set the availability to “Any” by default</li>
            <li>include disabled riders by default</li>
          </ul>
        </section>
        <section className='project-page-section'>
          <h5>Design exercise</h5>
          <p>I ran a quick information architecture exercise with the product designers and researchers that had experience working with the rider internal tools and teams that used them.</p>
          <p><em>Exercise:</em> Card sorting using data and actions from the audit to create a page structure and information hierarchy for the rider information page.</p>
          <p><em>Stimuli:</em> Survey findings, data analysis and previous experience working with the rider teams.</p>
          <DoubleImage
            src1={CardSort1}
            description1='Card sort in progress'
            src2={CardSort2}
            description2='Card sort output'
          />
          <p>A few patterns emerged between us:</p>
          <ul>
            <li>Information and actions that help solve problems to do with live orders should quickly accessible and scannable near the top. These need to be addressed now and they need to be addressed often. For example - viewing a riders recent orders and finding out a riders location.</li>
            <li>Lots of teams are linked straight into riders information page from another tool. Confirm that they are on the right page by identifying the rider clearly at the top of the page.</li>
            <li>Common groupings of information, such as a payment information section, contact details etc.</li>
          </ul>
        </section>
        <section className='project-page-section'>
          <h5>Design exploration</h5>
          <p>With the survey findings, data analysis, design exercise and the design system, the main structure of the product came together quickly.</p>
          <Image
            src={Hierarchy}
            description='Rider information page hierarchy'
          />
          <p>I spent some time exploring layouts to get the right balance between density and scannability, to have the important information immediately available.</p>
          <Image
            src={InfoPageExplorations}
            description='Four wireframe explorations of the rider information page layout'
          />
        </section>
        <section className='project-page-section'>
          <h5>Engineering scoping and feedback</h5>
          <p>Whilst I sat with engineers every day, we now had a good picture of all the data and functionality that we would need to port, so it was time for a deep dive. We ran through everything. It was clear there was far too much to port for first release, so we de-scoped and prioritised. This was ok for first release, as we could still link to some parts of the old admin.</p>
          <Image
            src={EngFeedback}
            description='Engineering feedback'
          />
        </section>
        <section className='project-page-section'>
          <h5>High fidelity design</h5>
          <p>With the design system and existing patterns, the high fidelity designs came together quickly. I stuck with a similar index page pattern as the rider payments sections. Filters on the left, table of results on the right.</p>
          <Image
            src={IndexPage}
            description='Rider index page'
          />
          <p>For the Rider Information page, we went with a page structure that ensured all the information to do with solving live orders.</p>
          <Image
            src={InfoPageTesting}
            description='Rider information page'
          />
        </section>
        <section className='project-page-section'>
          <h5>Testing</h5>
          <p>Our aim for the new product was to do no harm. We wanted to make sure that Deliveroo staff could still perform the tasks they needed too.</p>
          <p>Based on the survey findings, we tested the product with the teams that used it the most frequently and whose jobs were critical to live operations.</p>
          <p>Working with a researcher, we planned the objectives and discussion guide. Prototypes were a static click through, made with Marvel.</p>
          <p>We conducted 8 interviews with operations staff, asking them to run through the common tasks they usually perform, and then try and do them with the new designs.</p>
        </section>
        <section className='project-page-section'>
          <h5>Changes after testing</h5>
          <p>Feedback for the new product was generally positive with very few people struggling.</p>
          <p>We did change the page structure a bit to ensure everything to do with live orders was above the fold – turns out people rely on order history more than we thought. This involved creating the online card, a condensed version of the current order and current session cards that we tested. This was sticky, always available and freed up room for order history to sit near the top.</p>
          <Image
            src={InfoPageFinal}
            description='Updated rider information page after testing'
          />
        </section>
        <section className='project-page-section'>
          <h5>Design details</h5>
          <p>A few examples of the design details.</p>
          <ol>
            <li>
              <p>Bad status banner. If a rider accounts status isn’t normal (“offline” or “online”) the rider won’t be able to make deliveries. It turns out this was the answer to a lot of rider contacts to rider support. As a result, we popped a “bad status” banner at the top of the page, that only appeared when there was a problem. This breaks up the expected structure of the page and Deliveroo staff can immediately understand that there is a problem without reading anything.</p>
              <Image
                src={BadStatusBanner}
                description='Rider has a bad status bar'
              />
            </li>
            <li>
              <p>Flexible data points depending on the riders status.</p>
              <Image
                src={Details2}
                description='Different states of the rider status card'
              />
            </li>
            <li>
              <p>Editing data is done on a card by card basis so that information doesn't jump around the page as the inputs take up more room than the data on its own.</p>
              <Image
                src={Details3}
                description='Edit state for the rider information page'
              />
            </li>
          </ol>
        </section>
      </div>
      <h3 className='section-title'>Launch and impact</h3>
      <section className='project-page-section'>
        <p>Changing a product or tool that people use all day every day in such a drastic way is always going to be painful for some. We could have handled the change management better, and we had plans to.</p>
        <Image
          src={Welcome}
          description='Early explorations to include a new tools guide'
        />
        <p>Feedback was captured via a feedback link on the page. I set up a Slack bot to channel this feedback for all of the team to see and prioritise. After teams had a chance to use the tools for a week, the feedback was positive. It was clear there were one or two use cases we had missed, but this was inevitable in such a large organisation and we had planned time to iterate.</p>
        <p>We achieved our “do no harm” metric and set ourselves up, from an engineering and user experience standpoint, to work on future projects.</p>
      </section>
    </ProjectPageTemplate>
  )
}

export default RiderAdminPage