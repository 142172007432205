import React from 'react';
import { Link } from 'react-router-dom';
import './ProjectCard.css';

// Images

const ProjectCard = ({path, title, subtitle, imgsrc}) => {
  return (
    <div className='project-card'>
      <Link to={path}>
        {imgsrc ? 
            <img src={imgsrc} alt={`Cover for ${title}`}/>
            : null 
        }
        <h3>{title}</h3>
        <p>{subtitle}</p>
        <span className='view-project'>View project →</span>
      </Link>
    </div>
  )
}

export default ProjectCard