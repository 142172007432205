import React from 'react';

// Styles
import './DoubleImage.css'

const DoubleImage = ({ src1, description1, src2, description2 }) => {
  return (
    <div className='double-image-wrapper'>
      <figure>
        <a href={src1} target='_blank' rel='noreferrer'>
          <img src={src1} alt={description1} /> 
        </a>
        <figcaption>
          <span className='small-text lg'>
            {description1}. <a href={src1}><span className='lg'>View high res ↗</span></a>
          </span>
        </figcaption>
      </figure>
      <figure>
        <a href={src2} target='_blank' rel='noreferrer'>
          <img src={src2} alt={description2} />
        </a>
        <figcaption>
          <span className='small-text lg'>
            {description2}. <a href={src2}><span className='lg'>View high res ↗</span></a>
          </span>
        </figcaption>
      </figure>
    </div>
  )
}

export default DoubleImage;