import React from 'react'

// Styles
import './Highlight.css'

const Highlight = ({ emoji, children }) => {
  return (
    <div className='highlight'>
      { emoji ? (
          <span className='highlight-emoji'>
            {emoji} 
          </span> ) 
          : null 
        }
      { children ? (
        <span className='highlight-content'>
          {children} 
        </span> ) 
        : null 
      }
    </div>
  )
}

export default Highlight