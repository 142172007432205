import React from 'react'

// Components
import ProjectPageTemplate from '../../components/ProjectPageTemplate';
import Image from '../../components/Image';
import Highlight from '../../components/Highlight';

// Images
import ProofOfConceptGIF from '../../assets/images/flying-fish-sticks/proof-of-concept.gif'
import UIExploration from '../../assets/images/flying-fish-sticks/ui-exploration.png'

const WeatherApp = () => {
  return (
    <ProjectPageTemplate>
      <div>
        <h1 className='project-page-title'>Flying Fish Sticks</h1>
        <p>Flying Fish Sticks is a game based on <a href='http://www.annarbor.com/entertainment/eat-poop-you-cat-telestrations-review/' target='_blank' rel='noreferrer' className='lg'>Eat Poop You Cat</a> - something like a cross between the <a href='https://en.wikipedia.org/wiki/Chinese_whispers' target='_blank' rel='noreferrer' className='lg'>Telephone Game</a> and <a href='https://en.wikipedia.org/wiki/Pictionary' target='_blank' rel='noreferrer' className='lg'>Pictionary</a>.</p>
        <Highlight emoji ='👀'>This is not a full case study. If you are interested in seeing how I work in more detail, I suggest taking a look at some of my <a href='/'>Deliveroo case studies</a>.</Highlight>
      </div>
      <h3 className='section-title'>WIP</h3>
      <section className='project-page-section'>
        <p>The game is currently a work in progress.</p>
        <ul>
          <li><a href='https://github.com/roryhoulding/flying-fish-sticks' target='_blank' rel='noreferrer' className='lg'>Client repository</a></li>
          <li><a href='https://github.com/roryhoulding/flying-fish-sticks-server' target='_blank' rel='noreferrer' className='lg'>Server repository</a></li>
        </ul>
      </section>
      <h3 className='section-title'>Proof of concept</h3>
      <section className='project-page-section'>
        <p>I built a proof of concept to learn Socket.io, figure out how I was going to build the app and demonstrate the gameplay. The code is scrappy, but it served the purpose of experimenting how to build the game.</p>
        <ul>
          <li><a href='https://github.com/roryhoulding/draw-game-proof' target='_blank' rel='noreferrer' className='lg'>Proof of concept repository</a></li>
        </ul>
        <p>GIF:</p>
        <Image src={ProofOfConceptGIF} />
      </section>
      <h3 className='section-title'>Tech</h3>
      <section className='project-page-section'>
        <ul>
          <li><a className='lg' href='https://reactjs.org/' target='_blank' rel='noreferrer'>React</a></li>
          <li><a className='lg' href='https://nodejs.org/en/' target='_blank' rel='noreferrer'>Node</a> (with <a className='lg' href='https://expressjs.com/' target='_blank' rel='noreferrer'>Express</a>)</li>
          <li><a className='lg' href='https://socket.io/' target='_blank' rel='noreferrer'>Socket.io</a></li>
          <li><a className='lg' href='https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API' target='_blank' rel='noreferrer'>Canvas API</a></li>
        </ul>
      </section>
      <h3 className='section-title'>UI Exploration</h3>
      <section className='project-page-section'>
        <p>I've lossely explored some UI - opting for a drawn-by-hand style.</p>
        <Image src={UIExploration} />
      </section>
    </ProjectPageTemplate>
  )
}

export default WeatherApp