import React from 'react';
import { Link } from 'react-router-dom'

import Highlight from '../components/Highlight'

function NotFound() {
  return (
    <div className='center-content'>
      <div className='content-wrapper'>
        <Highlight>
          Sorry, I must have given you a bad link.
          If you want to see examples of my work, you can check them out in my <Link to='/'>portfolio</Link>.
          If you're interested in my expereience, here's my <Link to='/cv'>CV</Link>.
        </Highlight>
      </div>
    </div>
  );
}

export default NotFound;
