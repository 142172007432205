import React from 'react';
import { Link } from 'react-router-dom';

// Styles
import './IndexPage.css'

// Icons
import CVIcon from '../assets/icons/CVIcon';
import LinkedInIcon from '../assets/icons/LinkedinIcon';
import GithubIcon from '../assets/icons/GithubIcon';

// Componments
import ProjectCard from '../components/ProjectCard';
import ProjectCardCompact from '../components/ProjectCardCompact';

// Images
// import ProfilePic from '../assets/images/other/profile-pic.jpg'
import RiderOnboardingCover from '../assets/images/rider-onboarding/rider-onboarding-cover.jpg'
import InternalToolsCover from '../assets/images/internal-tools/cover.jpg';
import WeatherCover from '../assets/images/whats-with-the-weather/cover.jpg';
import BulbCover from '../assets/images/bulb-smart-pay-as-you-go/cover.jpg';

// File
import CV from '../assets/files/rory-houlding-cv.pdf';

function IndexPage() {
  return (
      <div className='content-wrapper'>
        <h3 className='section-title'>Rory Houlding</h3>
        <section className='intro'>
          <p>I’m a product designer, currently building tools for mortgage experts at <a href='https://better.com/' target='_blank' rel='noreferrer' className='bg'>Better</a>. Before that, I helped build a new customer service product at <a href='https://plain.com/' target='_blank' rel='noreferrer' className='pb'>Plain</a>, scaled two of the fastest growing European startups, <a href='https://deliveroo.co.uk' target='_blank' rel='noreferrer' className='dt'>Deliveroo</a> and <a className='bp' target='_blank' rel='noreferrer' href='https://bulb.co.uk'>Bulb</a>, and worked at the <a className='bb' target='_blank' rel='noreferrer' href='https://bbc.co.uk'>BBC</a>.</p>
          <p>I’m at my best working on large, multi-faceted problems with the ability to influence product strategy. I also have a keen interest in coding - Javascript with React and Node mostly.</p>
          <div class='intro-links-wrapper'>
            <a href={CV} target='_blank' rel='noreferrer' className='cv-link intro-link'>
              <CVIcon/> CV
            </a>
            <a href='https://www.linkedin.com/in/rory-houlding' target='_blank' rel='noreferrer' className='linkedin-link intro-link'><LinkedInIcon />LinkedIn</a>
            <a href='https://github.com/roryhoulding' target='_blank' rel='noreferrer' className='github-link intro-link'><GithubIcon />Github</a>
          </div>
        </section>
        <h3 className='section-title'>Selected work</h3>
        <section className='selected-work'>
          <ProjectCard
            path='/rider-onboarding'
            title='Rider onboarding experience at Deliveroo'
            subtitle='Deliveroo was Europe’s fastest growing company and needed to scale quickly. I led the design of the new rider onboarding experience and internal tools – helping to grow our rider fleet by 20% and reduce costs by 7%.'
            imgsrc={RiderOnboardingCover}
          />
          <ProjectCard
            path='/rider-admin'
            title='Internal tools for rider admin teams at Deliveroo'
            subtitle='Deliveroo’s rider tools are used by operations teams in 13 countries to manage 60,000 delivery riders. They had grown in a patch-work manner as Deliveroo scaled quickly, becoming inefficient and hard to use. With limited time and scope, I ran a data led (qual and quant) redesign.'
            imgsrc={InternalToolsCover}
          />
          <ProjectCard
            path='/smart-pay-as-you-go'
            title='Smart pay as you go for Bulb customers'
            subtitle='There are 4.3 million households with prepay energy meters in the UK. Bulb didn’t offer a smart meter prepay product and saw it as a major opportunity for growth. I designed and helped launch a beta in only a few months as they became the fastest growing European company in 2021.'
            imgsrc={BulbCover}
          />
          <ProjectCard
            path='/whats-with-the-weather'
            title='What’s with the weather?'
            subtitle='In 2020, I took some time off to improve my coding skills. This is one of many projects I built and my first with React. It’s not my most accomplished, but the one I had most fun with visually. Built with React, Netlify and the Open Weather API.'
            imgsrc={WeatherCover}
            />
        </section>
        <h3 className='section-title'>Other work</h3>
        <section className='project-section'>
          <ProjectCardCompact
            path='/nest-in-frame'
            title='Nest in frame'
            subtitle='Building a figma plugin'
            pill='personal'
          />
          <ProjectCardCompact
            path='/flying-fish-sticks'
            title='Flying Fish Sticks'
            subtitle='React, Node (with Express), Socket.io and Canvas API'
            pill='personal'
          />
          <ProjectCardCompact
            path='/tech-goose'
            title='Tech Goose'
            subtitle='React, Node (with Express), MongoDB (with Mongoose)'
            pill='personal'
          />
          <ProjectCardCompact
            path='/top-secret'
            title='A top secrect feature for Deliveroo retaurants and customers'
            subtitle='Workshops and co-creation'
            pill='deliveroo'
          />
          <ProjectCardCompact
            path='/rider-referrals'
            title='Referrals in the Deliveroo rider app'
            subtitle='A quick A/B test'
            pill='deliveroo'
          />
        </section>
      </div>
  );
}

export default IndexPage;
