import React from 'react'
import { Link } from 'react-router-dom'

// Files
import CVPDF from '../assets/files/rory-houlding-cv.pdf'

// Styles
import './CV.css'

export default function CV() {
  return (
    <div className='center-content'>
      <div className='content-wrapper cv-content'>
        <section className='header'>
          <div class='info'>
            <h5><Link to='/' class='dg'>Rory Houlding</Link></h5>
            <div className='contact'>
              <span>rohoulding@gmail.com</span>
              <span>(+44) 7544046686</span>
            </div>
            <Link to='/' class='lg'>Portfolio</Link>
            <div className='cv-download'>
            <a href={CVPDF} className='lg' download>Download CV as PDF</a> ⬇️
            </div>
          </div>
        </section>
        <section className='intro'>
          <p>I’m a product designer with five (and a half) years of experience. I consider myself a pragmatic and resourceful problem solver with a keen interest in coding. I’m at my best working on large, multi-faceted problems from scratch with the ability to influence product strategy. I ground my work in research and business goals.</p>
        </section>
        <section className='experience'>
          <div className='experience-item'>
            <div className='timeline'>
              <div className='timeline-vertical-line'></div>
              <div className='timeline-circle'></div>
            </div>
            <div className='experience-item-content'>
              <div className='experience-item-title'><strong>Resolve</strong> – Senior product designer (freelance)</div>
              <span className='small-text lg'>January 2021 – now</span>
              <span>I’m currently working with <Link to='https://getresolve.com'>Resolve</Link>, helping to design and define a brand new customer service tool. As the only designer at an early stage startup, I’m working across the entire spectrum - from designing the core experience for customer service reps and customers to creating the first set of design components.</span>
            </div>
          </div>
          <div className='experience-item'>
            <div className='timeline'>
              <div className='timeline-vertical-line'></div>
              <div className='timeline-circle'></div>
            </div>
            <div className='experience-item-content'>
              <div className='experience-item-title'><strong>Learning full stack Javascript</strong></div>
              <p className='small-text lg'>June 2020 - January 2021</p>
              <span>I took some time off work to improve my coding skills. I focused on learning a React, Node (with Express) and MongoDB stack but have dabbled in other tech too. I'm currently working on <Link to='/flying-fish-sticks' className='lg'>Flying Fish Sticks</Link> in my free time.</span>
            </div>
          </div>
          <div className='experience-item'>
            <div className='timeline'>
              <div className='timeline-vertical-line'></div>
              <div className='timeline-circle'></div>
            </div>
            <div className='experience-item-content'>
              <div className='experience-item-title'><strong>Bulb</strong> – Senior product designer (freelance)</div>
              <span className='small-text lg'>February 2020 - June 2020</span>
              {/* <span className='small-text lg'>Green energy provider. The UK's fastest growing startup in 2019.</span> */}
              <span>I was responsible for the beta design and exploring the future vision of a new product - Smart Pay As You Go. I supported and and enabled decision making through planning and running prioritisation workshops. With focused priorities and strong designer, developer and researcher collaboration, we created an MVP we were proud of in 2 months.</span>
            </div>
          </div>
          <div className='experience-item'>
            <div className='timeline'>
              <div className='timeline-vertical-line'></div>
              <div className='timeline-circle'></div>
            </div>
            <div className='experience-item-content'>
              <div className='experience-item-title'><strong>Work break</strong></div>
              <span className='small-text lg'>June 2019 - January 2020</span>
              <span>After two years at Deliveroo, I took some time off to cycle around the Balkans and work on personal projects.</span>
            </div>
          </div>
          <div className='experience-item'>
            <div className='timeline'>
              <div className='timeline-vertical-line'></div>
              <div className='timeline-circle'></div>
            </div>
            <div className='experience-item-content'>
              <div className='experience-item-title'><strong>Deliveroo</strong> – Product designer</div>
              <span className='small-text lg'>April 2017 - June 2019</span>
              {/* <span className='small-text lg'>A food Delivery company and one of the Europes most successful startups.</span> */}
              <span>I led the design of the new rider onboarding experience – a complex, end to end design challenge that saw us building products for prospective riders, operations teams and customer service. We saw a significant increase in conversion which helped ensure there were enough riders to deliver the food, reduced the cost of onboarding new riders saw big efficiency gains for Deliveroo staff. I also designed the rider referral programme, as well as working on the consumer and restaurant product projects.</span>
            </div>
          </div>
          <div className='experience-item'>
            <div className='timeline'>
              <div className='timeline-vertical-line'></div>
              <div className='timeline-circle'></div>
            </div>
            <div className='experience-item-content'>
              <div className='experience-item-title'><strong>BBC</strong> – UX designer</div>
              <span className='small-text lg'>September 2014 - April 2017</span>
              <span>I worked on myBBC, a cross product personalisation team but also contributed to iPlayer, BBC music and GEL (the BBC’s design system). I learnt a traditional user centred approach to design, helping to define and conduct research, wireframe, prototype concepts and see them through visual design to delivery.</span>
            </div>
          </div>
          <div className='experience-item'>
            <div className='timeline'>
              <div className='timeline-circle'></div>
            </div>
            <div className='experience-item-content'>
              <div className='experience-item-title'><strong>Cardiff University</strong> – BSc Architecture, 2:1</div>
              <span className='small-text lg'>September 2010 - July 2013</span>
              <span>I spent three years studying architecture at undergraduate level, earning a 2:1 BSc degree from the Welsh School of Architecture.</span>
            </div>
          </div>
        </section>
        <hr className='page-break'/>
        <section className='tools'>
          <div className='tools-title'><strong>Software and code</strong></div>
          <ul>
            <li>Pen and paper (always)</li>
            <li>Figma and Sketch</li>
            <li>Framer X, Marvel, Invision</li>
            <li>Whimsical for wireframes</li>
          </ul>
          <ul>
            <li>HTML and CS </li>
            <li>Javascript and React</li>
            <li>Node (with Express)</li>
            <li>MongoDB (with Mongoose)</li>
            <li>Heroku, Netlify, Github</li>
          </ul>
        </section>
      </div>
    </div>
  )
}