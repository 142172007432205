import React from 'react'

// Components
import ProjectPageTemplate from '../../components/ProjectPageTemplate';
import Image from '../../components/Image';
import Highlight from '../../components/Highlight';

// Images
import Cover from '../../assets/images/whats-with-the-weather/cover.jpg'
import Response from '../../assets/images/whats-with-the-weather/id-in-response.png'
import Data from '../../assets/images/whats-with-the-weather/data.png'
import TimeOfDay from '../../assets/images/whats-with-the-weather/time-of-day-example.png'
import Assets from '../../assets/images/whats-with-the-weather/assets.png'
import Moon from '../../assets/images/whats-with-the-weather/moon-example.png'
import Sun from '../../assets/images/whats-with-the-weather/sunset-example.png'

const WeatherApp = () => {
  return (
    <ProjectPageTemplate>
      <div>
        <h1 className='project-page-title'>What's with the weather?</h1>
        <p>In 2020, I took some time off to improve my coding skills. This is one of many projects I built and my first with React. It’s not my most accomplished, but the one I had most fun with visually. Built with React, Netlify and the Open Weather API.</p>
        <Highlight emoji ='🌞'><a href='https://whats-with-the-weather.netlify.app'>whats-with-the-weather.netlify.app</a></Highlight>
        <Image 
          src={Cover}
        />
      </div>
      <h3 className='section-title'>In a nutshell</h3>
      <section className='project-page-section'>
        <ul>
          <li>Gets the users location coordinates using geolocation from the <a target='_blank' rel='noreferrer' className='lg' href='https://developer.mozilla.org/en-US/docs/Web/API/Navigator'>Navigator Web API</a>.</li>
          <li>Uses those coordinates to fetch the weather from the <a target='_blank' rel='noreferrer' className='lg' href='https://openweathermap.org/api'>Open Weather API</a>. It does this via a <a target='_blank' rel='noreferrer' className='lg' href='https://www.netlify.com/products/functions/'>Netlify function to hide the API app id.</a></li>
          <li>Uses the weather response to generate a graphic from different combinations of react components based on the weather ID. The data for each graphic is stored in a hardcoded object of objects.</li>
          <li>Renders the graphic, location, temperature and weather description.</li>
        </ul>
      </section>
      <h3 className='section-title'>Tech</h3>
      <section className='project-page-section'>
        <ul>
          <li><a className='lg' href='https://reactjs.org/' target='_blank' rel='noreferrer'>React</a></li>
          <li><a className='lg' href='https://www.netlify.com/products/functions/' target='_blank' rel='noreferrer'>Netlify and Netlify functions</a></li>
          <li><a href='https://openweathermap.org/api' className='lg' target='_blank' rel='noreferrer'>Open Weather API</a></li>
          <li><a href='https://developer.mozilla.org/en-US/docs/Web/API/Navigator' className='lg' target='_blank' rel='noreferrer'>Navigator Web API</a></li>
        </ul>
      </section>
      <h3 className='section-title'>Detail</h3>
      <section className='project-page-section'>
        <p>The ID in the Weather API response is used to generate a graphic.</p>
        <Image src={Response}/>
        <p>The weather ID is used to lookup an object in a <a href='https://github.com/roryhoulding/weather-app/blob/master/src/data/graphicData.js' target='_blank' rel='noreferrer' className='lg'>harcoded file</a>. This object contains information about which react components to render depending on the time of day and the weather ID. This meant I needed only 15 assets to render all of the 50+ variations.</p>
        <Image src={Data}/>
        <p>Example of the same weather ID at different times of the day:</p>
        <Image src={TimeOfDay} />
        <p>All of the assets:</p>
        <Image src={Assets} />
        <p>The graphic is generated based on a 12x12 grid, with each component always rendering in the same location if it is rendered.</p>
        <Image src={Moon} />
        <Image src={Sun} />
      </section>
    </ProjectPageTemplate>
  )
}

export default WeatherApp