import React from 'react';

import './Pill.css';

const Pill = ({ text, color }) => {
    return (
        <div className={`pill pill-${color}`}>{text}</div>        
    )
};

export default Pill;